import type { RouterOptions } from '@nuxt/schema';

export default <RouterOptions>{
  scrollBehaviorType: 'smooth',
  // scrollBehavior: (_to, _from, savedPosition) => {
  //   if (savedPosition) return savedPosition;
  //
  //   document
  //     .querySelector('.global-scroll-viewport')
  //     ?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  //
  //   return {};
  // },
};
